import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  finalize,
  map,
  take,
  tap,
} from 'rxjs/operators';

import { AnalyticsService } from '@libs/src/analytics/analytics.service';
import { PostHogService } from '@libs/src/posthog/post-hog.service';
import { LocaleSettingsService } from '@main-client/src/app/core/locale-settings.service';

import type { Account } from '@libs/src/models/account.model';
import type { Group } from '@libs/src/models/group.model';
import type { Tenant } from '@libs/src/tenant/tenant.interface';
import { SetUser } from '@libs/src/user/user.actions';
import type { IAppState } from '@main-client/src/app/app.state';

import {
  AUTH_TOKEN_KEY,
  LocalStorageService,
  REFRESH_TOKEN_KEY,
} from '../core/local-storage.service';

import find from 'lodash-es/find';
import get from 'lodash-es/get';
import isEmpty from 'lodash-es/isEmpty';

const ROOT_URL = '/';
const USER_API_URL = '/api/account';
const SessionApiUrl = (sessionId: string) =>
  `/api/v2/account/sessions/${sessionId}`;

interface AdminStates {
  isPlatformAdmin: boolean;
  programManagerInGroupIds: Array<Group['_id']>;
}

@Injectable({ providedIn: 'root' })
export class UserService {
  tenantSource = this.store.select('tenant').pipe(
    filter((tenant: Tenant) => !isEmpty(tenant)),
    take(1),
  );
  userAdminStates = signal<AdminStates>({
    isPlatformAdmin: false,
    programManagerInGroupIds: [],
  });

  constructor(
    @Inject(LOCALE_ID) public appLocale: string,
    private readonly http: HttpClient,
    private readonly localeSettingsService: LocaleSettingsService,
    private readonly localStorageService: LocalStorageService,
    private readonly store: Store<IAppState>,
    private readonly analyticsService: AnalyticsService,
    private readonly postHogService: PostHogService,
  ) {}

  isLoggedIn(): boolean {
    return !!this.localStorageService.getItem(AUTH_TOKEN_KEY);
  }

  isLoggedIn$(): Observable<boolean> {
    return this.localStorageService.getItem$(AUTH_TOKEN_KEY).pipe(map(Boolean));
  }

  populate() {
    this.http
      .get<{
        adminStates?: AdminStates;
        profile: Account;
      }>(USER_API_URL)
      .pipe(
        tap(({ adminStates = {} }) => {
          const { isPlatformAdmin, programManagerInGroupIds = [] } =
            adminStates;
          this.userAdminStates.set({
            isPlatformAdmin,
            programManagerInGroupIds,
          });
        }),
        concatMap(({ profile }: { profile: Account }) =>
          this.tenantSource.pipe(
            take(1),
            map((tenant) => {
              this.localeSettingsService.ensureBrowserLocale(
                tenant,
                profile?.locale_config?.locale_selected,
              );
              return profile;
            }),
          ),
        ),
        tap((profile: Account) => {
          this.store.dispatch(SetUser(profile));
        }),
      )
      .subscribe();
  }

  logout() {
    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((userValue) => {
        const currentSessionQuery = {
          refresh_token: this.localStorageService.getItem(REFRESH_TOKEN_KEY),
        };
        const currentSession = find(userValue.sessions, currentSessionQuery);
        const currentSessionId = get(currentSession, '_id');
        if (!currentSessionId) {
          this._onLogOut();
          return;
        }

        this.http
          .delete(SessionApiUrl(currentSessionId))
          .pipe(
            catchError(() => EMPTY),
            finalize(() => this._onLogOut()),
          )
          .subscribe();
      });
  }

  async clearUserInfo() {
    this.localStorageService.removeItem(AUTH_TOKEN_KEY);
    this.localStorageService.removeItem(REFRESH_TOKEN_KEY);
    this.postHogService.reset();
    await this.analyticsService.clearAnalytics();
  }

  private async _onLogOut() {
    await this.clearUserInfo();
    this._refreshView();
  }

  private _refreshView() {
    window.location.href = ROOT_URL;
  }
}
